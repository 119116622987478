import { basicRequiredRule } from '@/template/rule'
import { getCommonParamsOptions } from '@/utils/common'

export const baseNewFormConfig = {
  formItems: [
    {
      field: 'fileUrl',
      type: 'file',
      label: 'file.file',
      otherOptions: {
        acceptType: '.zip,.gz',
        limitSize: 2 * 1024 * 1024
      }
    },
    {
      field: 'versionCode',
      type: 'input',
      label: 'file.version-code'
    },
    // {
    //   field: 'versionName',
    //   type: 'input',
    //   label: 'file.version-name'
    // },

    {
      field: 'otaEnvType',
      type: 'select',
      label: 'file.ota-env',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('ota_env_type')
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    versionCode: [basicRequiredRule],
    fileUrl: [basicRequiredRule],
    otaEnvType: [basicRequiredRule]
  }
}

<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'

import { baseNewFormConfig } from './config/new'

import { computed, ref, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
// import { isBase64 } from '../../../utils/util'

const routes = useRoute()
const router = useRouter()
const backPathArray = router?.options?.history?.state?.back?.split('/')
const paramId = backPathArray[backPathArray.length - 1]

const { t } = i18n.global

// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    routesName: 'OtaFileImport',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-ota-import'),
    pageType: 'add'
  }
]

// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()
// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
currentPageConfig.baseFormConfig = baseFormConfigRef
const configOptions = ref({})
configOptions.value = currentPageConfig

const id = routes.params.id

const { proxy, appContext } = getCurrentInstance()
const pageInfo = ref({})
const bus = appContext.config.globalProperties.$bus
const baseFormRef = ref('')
const imageObj = {}
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

// 5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const applicationInfo = await store.dispatch('file/handleGetApplication', {
    id
  })

  if (JSON.stringify(applicationInfo) !== '{}') {
    applicationInfo.date = proxy.$filters.fmtDateTime(applicationInfo.date)
    applicationInfo.createdTime = proxy.$filters.fmtDateTime(
      applicationInfo.createdTime
    )
    if (applicationInfo.screen && applicationInfo.screen[0]) {
      applicationInfo.screen = applicationInfo.screen[0]
    }
    if (pageType === 'edit' && applicationInfo.name && applicationInfo.appUrl) {
      const fileName =
        applicationInfo.name +
        applicationInfo.appUrl.substring(
          applicationInfo.appUrl.lastIndexOf('.')
        )
      bus.emit('showFileName', fileName)
    }
    applicationInfo.screens = applicationInfo.screenUrl
    applicationInfo.file = applicationInfo.appUrl
    applicationInfo.iconFile = applicationInfo.iconUrl
  }
  pageInfo.value = applicationInfo
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  data.fileUrl = store.state.system.currentFileList[routes?.path] || ''
  data.otaId = paramId
  handleMessageCommit('file/handleImportOtaFile', data).then(() => {
    router.push(`/file/ota/view/${paramId}`)
  })
}

// 该接口仅用于后端记录下载次数，下载文件直接访问url即可
// const downLoadApp = async () => {
//   store.dispatch('file/downLoadApp', id)
// }
onMounted(() => {
  bus.on('imageFile', (data) => {
    imageObj[data.field] = data.file
  })
})
</script>

<style scoped lang="scss">
.base-form {
  .form-item {
    padding: 5px 20px;
    .file-wrap {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: #64b5fc;
      }
      .download-icon {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}
</style>
